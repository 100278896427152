import { Builder } from 'builder-pattern';
import { create } from 'zustand';
import { loadingStore } from '@modules/core/adapter/outbound/stores/ui.store';
import { GetTikTokVdoByHashtagUsecase } from '@modules/trends/application/usecases/getTikTokVdoByHashtag.usecase';
import { ITikTokTrend } from '@modules/trends/domain/models/tiktok.model';
import { TikTokVdoByHashtagRepositoryImpl } from '../../inbound/repositories/tiktokVdoByHashtag.repository';
export interface ITikTokVdoByHashtagList {
  [x: string]: ITikTokTrend[];
}
interface TikTokVdoByHashtagState {
  tiktokVdoByHashtag: ITikTokVdoByHashtagList;
  setTikTokVdoByHashtag: (hashtag: string) => Promise<void>;
}
export const tiktokVdoByHashtagStore = create<TikTokVdoByHashtagState>((set, get) => ({
  tiktokVdoByHashtag: Builder<ITikTokVdoByHashtagList>().build(),
  setTikTokVdoByHashtag: async hashtag => {
    try {
      loadingStore.setState({
        isLoading: true
      });
      const tiktokVdoByHashtag = get().tiktokVdoByHashtag;
      const tiktokVdoByHashtagRepositoryImpl = new TikTokVdoByHashtagRepositoryImpl();
      const getTikTokVdoByHashtagUsecase = new GetTikTokVdoByHashtagUsecase(tiktokVdoByHashtagRepositoryImpl);
      const result = await getTikTokVdoByHashtagUsecase.handle(hashtag);
      if (result.isRight()) {
        set(() => ({
          tiktokVdoByHashtag: {
            ...tiktokVdoByHashtag,
            ...result.value
          }
        }));
      }
    } catch (err) {
      console.error('error setTikTokVdoByHashtag', err);
    } finally {
      loadingStore.setState({
        isLoading: false
      });
    }
  }
}));