import { TiktokVideoResponse } from '@modules/libs/graphql/generated/graphql';
import { ITikTokTrend } from '@modules/trends/domain/models/tiktok.model';
export class TikTokVdoByHashtagEntityMapper {
  public static toDomain(tiktokVdoResponse: TiktokVideoResponse): ITikTokTrend {
    return {
      id: tiktokVdoResponse.id,
      tiktokThumbnailUrl: tiktokVdoResponse.tiktokThumbUrl || '',
      thumbnailUrl: tiktokVdoResponse.thumbUrl || '',
      videoUrl: tiktokVdoResponse.videoUrl,
      viewer: tiktokVdoResponse.viewCount
    };
  }
}