import { BannerResponse, GetAllBannerByPageResponse } from '@modules/libs/graphql/generated/graphql';
import { getLinkoutPrefixHttps } from '@shared/helpers/linkout.helper';
import { IBanner, IBannerList } from '@shared/models/banner.model';
export class BannerEntityMapper {
  public static toDomain(bannerListResponse: GetAllBannerByPageResponse): IBannerList {
    return {
      highlight: bannerListResponse.highlight.map(highlight => this.bannerDetailMapper(highlight)),
      bottom: bannerListResponse.bottom.map(bottom => this.bannerDetailMapper(bottom))
    };
  }
  private static bannerDetailMapper(bannerResponse: BannerResponse): IBanner {
    return {
      id: bannerResponse.id.toString(),
      bannerDesktop: bannerResponse.bannerDesktop,
      bannerMobile: bannerResponse.bannerMobile,
      linkOutUrl: getLinkoutPrefixHttps(bannerResponse.linkOutUrl)
    };
  }
}