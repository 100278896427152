import Image from 'next/image';
import styled from 'styled-components';
export const BannerRegister = styled.div.withConfig({
  displayName: "RegisterBannerstyle__BannerRegister",
  componentId: "sc-1bwffmr-0"
})(["", " &:hover > a > div{", " -webkit-filter:blur(30px);filter:blur(30px);transition:all 0.2s ease-in-out;}"], {
  "position": "relative"
}, {
  "top": "25px",
  "opacity": "0.8"
});
export const ImgBanner = styled(Image).withConfig({
  displayName: "RegisterBannerstyle__ImgBanner",
  componentId: "sc-1bwffmr-1"
})(["", ""], {
  "position": "relative",
  "zIndex": "10",
  "height": "auto",
  "width": "100%",
  "borderRadius": "20px"
});
export const ReflexShadow = styled.div.withConfig({
  displayName: "RegisterBannerstyle__ReflexShadow",
  componentId: "sc-1bwffmr-2"
})(["", " -webkit-filter:blur(0px);filter:blur(0px);transition:all 0.2s ease-in-out;@media (max-width:", "){", "}"], {
  "position": "absolute",
  "left": "0px",
  "right": "0px",
  "top": "0px",
  "zIndex": "0",
  "borderRadius": "20px",
  "opacity": "0"
}, ({
  theme
}) => theme.breakpoint.md, {
  "display": "none"
});