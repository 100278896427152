import Router from 'next/router';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { GetNewCollectionUsecase } from '@modules/collections/application/usecases/getNewCollection.usecase';
import { INewCollectionList } from '@modules/collections/domain/models/newCollection.model';
import { loadingStore } from '@modules/core/adapter/outbound/stores/ui.store';
import { StatusCodeType } from '@shared/types/statusCode.type';
import { NewCollectionRepositoryImpl } from '../../inbound/repositories/newCollection.repository';
interface NewCollectionState {
  newCollectionList: INewCollectionList[];
  setNewCollection: () => void;
}
export const newCollectionStore = create<NewCollectionState>()(devtools(set => ({
  newCollectionList: [],
  setNewCollection: async (): Promise<void> => {
    try {
      loadingStore.setState({
        isLoading: true
      });
      const newCollectionRepositoryImpl = new NewCollectionRepositoryImpl();
      const getNewCollectionUsecase = new GetNewCollectionUsecase(newCollectionRepositoryImpl);
      const result = await getNewCollectionUsecase.handle();
      if (result.isRight()) {
        set(() => ({
          newCollectionList: result.value
        }));
      }
    } catch (err) {
      console.error('error setNewCollection', err);
      if (err.statusCode === StatusCodeType.NotFound) {
        Router.push('/404');
      }
    } finally {
      loadingStore.setState({
        isLoading: false
      });
    }
  }
})));