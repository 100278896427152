import { TikTokHashtagRepositoryImp } from '@modules/trends/adapter/inbound/repositories/tiktokHashtag.repository';
import { IGetTikTokHashtagUsecase } from '@modules/trends/domain/ports/getTikTokHashtag.usecase.port';
import { left, right } from '@shared/utils/either.utils';
export class GetTikTokHashtagUsecase implements IGetTikTokHashtagUsecase {
  constructor(private readonly tiktokRepo: TikTokHashtagRepositoryImp) {}
  async handle(): IGetTikTokHashtagUsecase.getTikTokHashtagOutput {
    const result = await this.tiktokRepo.getTikTokHashtag();
    const output = result.value;
    if (result.isLeft()) return left([]);
    return right(output);
  }
}