import { IGetTikTokVdoByHashtagUsecase } from '@modules/trends/domain/ports/getTikTokVdoByHashtag.usecase.port';
import { left, right } from '@shared/utils/either.utils';
import { ITikTokVdoByHashtagRepository } from '../ports/tiktokVdoByHashtag.repository.port';
export class GetTikTokVdoByHashtagUsecase implements IGetTikTokVdoByHashtagUsecase {
  constructor(private readonly tiktokVdoByHashtagRepository: ITikTokVdoByHashtagRepository) {}
  async handle(hashtag: string): IGetTikTokVdoByHashtagUsecase.getByHashtagOutput {
    const result = await this.tiktokVdoByHashtagRepository.getByHashtag(hashtag);
    const value = result.value;
    if (result.isLeft()) return left(value);
    const output = {
      [hashtag]: value
    };
    return right(output);
  }
}