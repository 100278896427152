import { GetTikTokVdoByHashtagQueryDocument, TiktokVideoResponse } from '@modules/libs/graphql/generated/graphql';
import { gqlQuery } from '@modules/libs/graphql/gql-config';
import { ITikTokVdoByHashtagRepository } from '@modules/trends/application/ports/tiktokVdoByHashtag.repository.port';
import { left, right } from '@shared/utils/either.utils';
import { TikTokVdoByHashtagEntityMapper } from '../../outbound/ports/tiktokVdoByHashtag.mapper';
export class TikTokVdoByHashtagRepositoryImpl implements ITikTokVdoByHashtagRepository {
  async getByHashtag(hashtag: string): ITikTokVdoByHashtagRepository.getByHashtagOutput {
    try {
      const {
        data
      } = await gqlQuery({
        query: GetTikTokVdoByHashtagQueryDocument,
        variables: {
          where: {
            tiktokHashtag: hashtag
          }
        }
      });
      const tiktokVdoList = data.getTiktokVideoByHashtag.map((tiktokVdo: TiktokVideoResponse) => TikTokVdoByHashtagEntityMapper.toDomain(tiktokVdo));
      return right(tiktokVdoList);
    } catch (error: any) {
      console.error('error TikTokVdoByHashtagRepositoryImpl', error);
      return left(error);
    }
  }
}