import { GetTikTokHashtagQueryDocument } from '@modules/libs/graphql/generated/graphql';
import { gqlQuery } from '@modules/libs/graphql/gql-config';
import { ITikTokHashtagRepository } from '@modules/trends/application/ports/tiktokHashtag.repository.port';
import { ITikTokHashtag } from '@modules/trends/domain/models/tiktok.model';
import { left, right } from '@shared/utils/either.utils';
import { TikTokHashtagEntityMapper } from '../../outbound/ports/tiktokHashtag.mapper';
export class TikTokHashtagRepositoryImp implements ITikTokHashtagRepository {
  async getTikTokHashtag(): ITikTokHashtagRepository.getTikTokHashtagOutput {
    try {
      const {
        data
      } = await gqlQuery({
        query: GetTikTokHashtagQueryDocument
      });
      const output: ITikTokHashtag[] = TikTokHashtagEntityMapper.toDomainList(data.getTiktokHashtag);
      return right(output);
    } catch (err: any) {
      console.error('Error TikTokHashtagRepositoryImp', err);
      return left(err);
    }
  }
}