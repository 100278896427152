import { IBannerRepository } from '@modules/banner/application/ports/banner.repository.port';
import { GetBannerByPageDocument } from '@modules/libs/graphql/generated/graphql';
import { gqlQuery } from '@modules/libs/graphql/gql-config';
import { left, right } from '@shared/utils/either.utils';
import { BannerEntityMapper } from '../../outbound/ports/banner.mapper';
export class BannerRepositoryImpl implements IBannerRepository {
  async getAllByPage(page: string): IBannerRepository.getAllByPageOutput {
    try {
      const {
        data
      } = await gqlQuery({
        query: GetBannerByPageDocument,
        variables: {
          where: {
            page
          }
        }
      });
      const output = BannerEntityMapper.toDomain(data.getBannerByPage);
      return right(output);
    } catch (err) {
      console.error('Error BannerRepositoryImpl', err);
      return left(err);
    }
  }
}