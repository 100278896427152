import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { loadingStore } from '@modules/core/adapter/outbound/stores/ui.store';
import { RegisterBannerType } from '@modules/libs/graphql/generated/graphql';
import { GetRegisterBannerUsecase } from '@modules/registerBanner/application/usecases/getRegisterBanner.usecase';
import { IRegisterBanner } from '@modules/registerBanner/domain/models/registerBanner.model';
import { RegisterBannerRepositoryImpl } from '../../inbound/repositories/registerBanner.repository';
interface RegisterBannerState {
  registerBanner: IRegisterBanner;
  setRegisterBanner: (registeredType: RegisterBannerType) => Promise<void>;
}
export const registerBannerStore = create<RegisterBannerState>()(devtools(set => ({
  registerBanner: null,
  setRegisterBanner: async (registeredType: RegisterBannerType): Promise<void> => {
    try {
      loadingStore.setState({
        isLoading: true
      });
      const registerBannerRepositoryImpl = new RegisterBannerRepositoryImpl();
      const getRegisterBannerUsecase = new GetRegisterBannerUsecase(registerBannerRepositoryImpl);
      const result = await getRegisterBannerUsecase.handle(registeredType);
      if (result.isRight()) {
        set(() => ({
          registerBanner: result.value
        }));
      }
    } catch (error) {
      console.error('Error setRegisterBannerStore', error);
    } finally {
      loadingStore.setState({
        isLoading: false
      });
    }
  }
})));