import { INewCollection, INewCollectionList, IRecipeList, IRecipeThumb } from '@modules/collections/domain/models/newCollection.model';
import { IGetNewCollectionUsecase } from '@modules/collections/domain/ports/getNewCollection.usecase.port';
import { left, right } from '@shared/utils/either.utils';
import { shuffleArrayWithLimitOutput } from '@shared/utils/random.utils';
import { INewCollectionRepository } from '../ports/newCollection.repository.port';
export class GetNewCollectionUsecase implements IGetNewCollectionUsecase {
  constructor(private readonly newCollectionRepository: INewCollectionRepository) {}
  async handle(): IGetNewCollectionUsecase.getNewCollectionOutput {
    const result = await this.newCollectionRepository.getNewCollection();
    const value = result.value;
    if (result.isLeft()) return left(value);
    const output: INewCollectionList[] = value.map((collectionItem: INewCollection) => {
      return {
        ...collectionItem,
        recipeList: this._handleRecipeList(collectionItem.recipeList)
      };
    });
    return right(output);
  }
  _handleRecipeList(recipes: IRecipeThumb[]): IRecipeList {
    const shuffleRecipe = shuffleArrayWithLimitOutput(recipes, 7);
    return {
      thumbS: shuffleRecipe.slice(0, 3),
      thumbM: shuffleRecipe.slice(3, 7)
    };
  }
}