import { TiktokHashtagResponse } from '@modules/libs/graphql/generated/graphql';
import { ITikTokHashtag } from '@modules/trends/domain/models/tiktok.model';
export class TikTokHashtagEntityMapper {
  public static toDomainList(tiktokList: TiktokHashtagResponse[]): ITikTokHashtag[] {
    return tiktokList?.map(tiktok => this.toDomain(tiktok));
  }
  public static toDomain(tiktok: TiktokHashtagResponse): ITikTokHashtag {
    return {
      id: tiktok.id.toString(),
      title: tiktok.title,
      hashtag: tiktok.hashtag
    };
  }
}