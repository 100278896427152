import { IGetAllBannerByPageUsecase } from '@modules/banner/domain/ports/banner.usecase.port';
import { left, right } from '@shared/utils/either.utils';
import { IBannerRepository } from '../ports/banner.repository.port';
export class GetAllBannerByPageUsecase implements IGetAllBannerByPageUsecase {
  constructor(private readonly bannerRepository: IBannerRepository) {}
  async handle(page: string): IGetAllBannerByPageUsecase.getAllByPageOutput {
    let currentPage = page.split('/')[1];
    if (currentPage === '') currentPage = 'home';
    if (currentPage === 'news') currentPage = 'activity';
    const result = await this.bannerRepository.getAllByPage(currentPage);
    const value = result.value;
    if (result.isLeft()) return left([]);
    return right(value);
  }
}