import { RegisterBannerType } from '@modules/libs/graphql/generated/graphql';
import { IGetRegisterBannerUsecase } from '@modules/registerBanner/domain/ports/getRegisterBanner.usecase.port';
import { left, right } from '@shared/utils/either.utils';
import { IRegisterBannerRepository } from '../ports/registerBanner.repository.port';
export class GetRegisterBannerUsecase implements IGetRegisterBannerUsecase {
  constructor(private readonly registerBannerRepository: IRegisterBannerRepository) {}
  async handle(registeredType: RegisterBannerType): IGetRegisterBannerUsecase.getRegisterBannerOutput {
    const result = await this.registerBannerRepository.getRegisterBanner(registeredType);
    const value = result.value;
    if (result.isLeft()) return left(null);
    return right(value);
  }
}