import { GetRegisterBannerQueryDocument, RegisterBannerType } from '@modules/libs/graphql/generated/graphql';
import { gqlQuery } from '@modules/libs/graphql/gql-config';
import { IRegisterBannerRepository } from '@modules/registerBanner/application/ports/registerBanner.repository.port';
import { IRegisterBanner } from '@modules/registerBanner/domain/models/registerBanner.model';
import { left, right } from '@shared/utils/either.utils';
import { RegisterBannerEntityMapper } from '../../outbound/ports/registerBanner.mapper';
export class RegisterBannerRepositoryImpl implements IRegisterBannerRepository {
  async getRegisterBanner(registeredType: RegisterBannerType): IRegisterBannerRepository.getRegisterBannerOutput {
    try {
      const {
        data
      } = await gqlQuery({
        query: GetRegisterBannerQueryDocument,
        variables: {
          where: {
            registerBannerType: registeredType
          }
        }
      });
      const output: IRegisterBanner = RegisterBannerEntityMapper.toDomain(data.getRegisterBanner);
      return right(output);
    } catch (err) {
      console.error('Error RegisterBannerRepositoryImpl', err);
      return left(err);
    }
  }
}