import { isEmpty, random } from 'radash';
import { INewCollection, IRecipeThumb } from '@modules/collections/domain/models/newCollection.model';
import { CollectionWithRecipeResponse, RecipeResponse } from '@modules/libs/graphql/generated/graphql';
export class NewCollectionEntityMapper {
  public static toDomain(collectionResponse: CollectionWithRecipeResponse): INewCollection {
    return {
      id: collectionResponse.id,
      name: collectionResponse.title,
      menuAmount: collectionResponse.recipeListTotal ?? 0,
      imageUrl: collectionResponse.thumbUrl ?? '',
      videoUrl: collectionResponse.video,
      slug: collectionResponse.slugUrl,
      recipeList: this.recipeListMapper(collectionResponse.recipeList)
    };
  }
  private static recipeListMapper(recipeList: RecipeResponse[]): IRecipeThumb[] {
    return recipeList.map(recipe => {
      return {
        name: recipe.title,
        imageUrl: recipe.thumbUrl,
        // viewer: recipe.viewer, //waiting for viewer key from BE
        viewer: random(0, 50000),
        slug: isEmpty(recipe.slugUrl) ? recipe.id.toString() : recipe.slugUrl,
        // relatedProductUrl: recipe.relatedProductUrl, //waiting for relatedProductUrl key from BE
        relatedProductUrl: ''
      };
    });
  }
}